import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=c6b49c8c&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=js"
export * from "./Payment.vue?vue&type=script&lang=js"
import style0 from "./Payment.vue?vue&type=style&index=0&id=c6b49c8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b49c8c",
  null
  
)

export default component.exports